var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "container pa-1 mt-2"
  }, [_c('SearchInputContainer', {
    attrs: {
      "autofocus": _vm.autofocus,
      "entity-type": _vm.EntityType.ACTIVITY,
      "hint": _vm.hint,
      "is-loading": _vm.isLoading,
      "placeholder": _vm.placeholder
    },
    on: {
      "searchButtonClicked": _vm.onClickOfSearchButton,
      "enterPressed": _vm.onClickOfSearchButton
    },
    model: {
      value: _vm.searchInput,
      callback: function ($$v) {
        _vm.searchInput = $$v;
      },
      expression: "searchInput"
    }
  }), _c('SearchFilterDisplay', {
    attrs: {
      "active": _vm.shouldShowFilters,
      "color": _vm.EntityType.ACTIVITY,
      "entity-type": _vm.EntityType.ACTIVITY,
      "chipsFromFilters": _vm.chipsFromFilters,
      "show-filter-button": _vm.showFilterButton
    },
    on: {
      "appChipCloseClicked": _vm.onClickOfRemoveFilter,
      "clearAllFilters": _vm.onClearAllFilters,
      "filterButtonClicked": _vm.onClickOfFilterIcon
    }
  }), _c('SearchFilterContainer', {
    attrs: {
      "color": _vm.EntityType.ACTIVITY,
      "show": _vm.shouldShowFilters
    }
  }, [_c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs6": "",
      "pb-2": "",
      "pr-3": ""
    }
  }, [_c('AppSelectEnum', {
    attrs: {
      "chips": false,
      "color": _vm.EntityType.ACTIVITY,
      "disabled": _vm.isFilterLocked('subType'),
      "display": _vm.ActivityTypeDisplay,
      "enum": _vm.ActivityType,
      "sort": false,
      "label": "Type"
    },
    model: {
      value: _vm.searchFilters.subType,
      callback: function ($$v) {
        _vm.$set(_vm.searchFilters, "subType", $$v);
      },
      expression: "searchFilters.subType"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs6": ""
    }
  }, [_c('c-text', {
    attrs: {
      "label": "Authority number",
      "c-value": _vm.searchFilters.authorityNumber
    },
    on: {
      "c-input": function ($event) {
        _vm.searchFilters.authorityNumber = $event.detail;
      }
    }
  })], 1)], 1), _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs6": "",
      "pr-3": ""
    }
  }, [_c('AppSelectDrawingNumber', {
    attrs: {
      "disabled": _vm.isFilterLocked('drawingNumber')
    },
    model: {
      value: _vm.searchFilters.drawingNumber,
      callback: function ($$v) {
        _vm.$set(_vm.searchFilters, "drawingNumber", $$v);
      },
      expression: "searchFilters.drawingNumber"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs6": ""
    }
  }, [_c('AppSelectAsBuilt', {
    attrs: {
      "label": "AsBuilt",
      "disabled": _vm.isFilterLocked('asBuilt')
    },
    model: {
      value: _vm.searchFilters.asBuilt,
      callback: function ($$v) {
        _vm.$set(_vm.searchFilters, "asBuilt", $$v);
      },
      expression: "searchFilters.asBuilt"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs6": "",
      "pr-3": ""
    }
  }, [false ? _c('AppSelectEnum', {
    attrs: {
      "chips": false,
      "color": _vm.EntityType.ACTIVITY,
      "display": _vm.PressurizationRangeDisplay,
      "enum": _vm.PressurizationRange,
      "clearable": "",
      "label": "Pressurization range"
    },
    model: {
      value: _vm.searchFilters.pressurizationRange,
      callback: function ($$v) {
        _vm.$set(_vm.searchFilters, "pressurizationRange", $$v);
      },
      expression: "searchFilters.pressurizationRange"
    }
  }) : _vm._e()], 1), _c('v-flex', {
    attrs: {
      "xs6": ""
    }
  }, [false ? _c('AppBoolean', {
    staticClass: "mt-2 d-inline-block",
    attrs: {
      "label": "Preventative maintenance?"
    },
    model: {
      value: _vm.searchFilters.isPreventativeMaintenance,
      callback: function ($$v) {
        _vm.$set(_vm.searchFilters, "isPreventativeMaintenance", $$v);
      },
      expression: "searchFilters.isPreventativeMaintenance"
    }
  }) : _vm._e()], 1)], 1), _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": "",
      "sm6": "",
      "pr-3": "",
      "mt-2": ""
    }
  }, [_c('AppDate', {
    attrs: {
      "label": "Activity start"
    },
    model: {
      value: _vm.searchFilters.startDate,
      callback: function ($$v) {
        _vm.$set(_vm.searchFilters, "startDate", $$v);
      },
      expression: "searchFilters.startDate"
    }
  })], 1)], 1), _c('v-layout', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showMaxResults,
      expression: "showMaxResults"
    }]
  }, [_c('v-flex', {
    attrs: {
      "xs3": "",
      "mt-2": ""
    }
  }, [_c('AppNumber', {
    attrs: {
      "label": "Max results",
      "wholeNumber": "",
      "min": 0
    },
    model: {
      value: _vm.userSelectedMaxResults,
      callback: function ($$v) {
        _vm.userSelectedMaxResults = $$v;
      },
      expression: "userSelectedMaxResults"
    }
  })], 1)], 1)], 1), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_c('AppLoader', {
    staticClass: "ma-0",
    attrs: {
      "color": _vm.EntityType.ACTIVITY,
      "show": _vm.isLoading,
      "type": "linear"
    }
  })], 1), _c('v-slide-y-transition', {
    attrs: {
      "tag": "table",
      "hide-on-leave": ""
    }
  }, [_vm.shouldShowTable ? _c('AppTableActions', {
    key: "searchResults",
    attrs: {
      "color": _vm.EntityType.ACTIVITY,
      "headers": _vm.computedTableHeaders,
      "items": _vm.tableItems,
      "dense-rows": "",
      "show-header": "",
      "title": "Activities"
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function ({
        item
      }) {
        return [item.id ? _c('ButtonMiniActionManage', {
          attrs: {
            "to": `/${_vm.EntityType.ACTIVITY}/manage/${item.id}#information`,
            "tooltip": "Navigate to the Activity's manage page."
          }
        }) : _vm._e()];
      }
    }], null, false, 1569581178)
  }) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };