var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppModal', {
    attrs: {
      "color": _vm.EntityType.HARDWARE_LIST,
      "hide-title-section": "",
      "modal-width": "99vw"
    },
    on: {
      "modal-close": _vm.emitModalClose,
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) return null;
        return _vm.emitModalClose.apply(null, arguments);
      }
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [!_vm.hardwareListPart ? _c('AppLoader', {
          attrs: {
            "color": _vm.EntityType.HARDWARE_LIST,
            "type": "linear"
          }
        }) : _vm._e(), _vm.hardwareListPart ? _c('div', {
          staticClass: "c-grid"
        }, [_c('div', {
          staticClass: "col-full mb-1"
        }, [_c('MiniHardwareList', {
          attrs: {
            "title": "Current list",
            "id": _vm.hardwareListId
          }
        })], 1), _c('div', {
          staticClass: "col-sixth mb-2"
        }, [_c('AppNumber', {
          attrs: {
            "label": "Sequence",
            "tooltip": "This controls the order of the items of the list.",
            "tooltip-position": "right"
          },
          model: {
            value: _vm.formData.sequence,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "sequence", $$v);
            },
            expression: "formData.sequence"
          }
        })], 1), _c('div', {
          staticClass: "col-full"
        }, [_vm.hardwareListPart && _vm.hardwareListPart.partReferenceId ? _c('SelectorPartReference', {
          attrs: {
            "part-reference-id": _vm.hardwareListPart.partReferenceId
          },
          on: {
            "edited-part-reference-selected": _vm.onPartReferenceEdited,
            "new-part-reference-selected": _vm.onNewPartReferenceSelected,
            "part-reference-selected": _vm.onPartReferenceSelected
          }
        }) : _vm._e()], 1)]) : _vm._e(), _vm.hardwareListPart ? _c('div', {
          staticClass: "c-grid mt-2"
        }, [_c('div', {
          staticClass: "col-sixth"
        }, [_c('AppNumber', {
          attrs: {
            "label": "Qty"
          },
          model: {
            value: _vm.formData.quantity,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "quantity", $$v);
            },
            expression: "formData.quantity"
          }
        })], 1), _c('div', {
          staticClass: "col-third"
        }, [_c('AppNumber', {
          attrs: {
            "clearable": "",
            "label": "Addendum number"
          },
          model: {
            value: _vm.formData.addendumNumber,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "addendumNumber", $$v);
            },
            expression: "formData.addendumNumber"
          }
        })], 1), _c('div', {
          staticClass: "col-fourth"
        }, [_c('AppDate', {
          attrs: {
            "label": "Addendum"
          },
          model: {
            value: _vm.formData.addendumDate,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "addendumDate", $$v);
            },
            expression: "formData.addendumDate"
          }
        })], 1), _c('div', {
          staticClass: "col-full mt-2"
        }, [_c('HardwareListNoteDdl', {
          attrs: {
            "multiple": ""
          },
          model: {
            value: _vm.notes,
            callback: function ($$v) {
              _vm.notes = $$v;
            },
            expression: "notes"
          }
        })], 1)]) : _vm._e()];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('ButtonSave', {
          attrs: {
            "disabled": _vm.shouldDisableSaveButton,
            "is-saving": _vm.isSaving
          },
          on: {
            "click": _vm.onClickOfSave
          }
        }), _c('ButtonCancel', {
          on: {
            "click": _vm.emitModalClose
          }
        })];
      },
      proxy: true
    }])
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };